
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";

// import { ElLoading } from "element-plus";
// let loadingEl: any;

export default defineComponent({
  created() {
    this.getList();
  },
  name: "Respondents",
  data() {
    return {
      searching: "",
      assessmentsID: "",
      sections: 3,
      selectedStatus: "",
      selectedRating: "",
      selectedAssessment: "",
      selectOption: [
        {
          value: "INVITED",
          label: "optional.Invited",
        },
        {
          value: "TODO",
          label: "optional.Started",
        },
        {
          value: "IN_PROGRESS",
          label: "optional.Incompleted",
        },
        {
          value: "COMPLETED",
          label: "optional.Completed",
        },
      ],
      selectRating: [
        {
          value: "1",
          label: "★",
        },
        {
          value: "2",
          label: "★★",
        },
        {
          value: "3",
          label: "★★★",
        },
        {
          value: "4",
          label: "★★★★",
        },
        {
          value: "5",
          label: "★★★★★",
        },
      ],
      publicLink: "happily.ai/company/a8dn",
      // cadidateData: [
      //   {
      //     candidateName: "Jack Dee",
      //     id: "11s11ds1z1f1e1",
      //     joined: "March 22, 2022",
      //     score: "-",
      //     status: "Started",
      //     yourRating: 0,
      //   },
      //   {
      //     candidateName: "David Mitchell",
      //     id: "11d11f111ads2d2212",
      //     joined: "March 22, 2022",
      //     score: "0",
      //     status: "Completed",
      //     yourRating: 0.5,
      //   },
      // ],
      form: {
        firstname: "",
        lastname: "",
        email: "",
      },
      formValidate: {
        email: false,
      },
    };
  },
  // watch: {
  //   loading(value) {
  //     if (value) {
  //       loadingEl = ElLoading.service({
  //         lock: true,
  //         text: "Loading..",
  //         background: "#ffffff90",
  //       });
  //     } else {
  //       loadingEl.close();
  //     }
  //   },
  // },
  computed: {
    selectAssessment() {
      return this.$store.getters["respondent/itemsAssessment"];
    },
    candidateData() {
      let candidateData = this.$store.getters["respondent/itemscandidate"];
      const searching = this.searching;
      const selectedStatus = this.selectedStatus;
      const selectedRating = this.selectedRating;
      const selectedAssessment = this.selectedAssessment;
      candidateData = candidateData.filter((data: any) => {
        let result = Boolean(
          !searching ||
            data.candidateName
              .toLowerCase()
              .includes(searching.toLowerCase()) ||
            data.email.toLowerCase().includes(searching.toLowerCase())
        );
        if (selectedStatus) {
          result = result && data.status == selectedStatus;
        }
        if (selectedRating) {
          result = result && data.rating == selectedRating;
        }
        if (selectedAssessment) {
          const temp = data.id.split("-");
          result = result && temp[0] == selectedAssessment;
        }
        return result;
      });
      return candidateData;
    },
    loading() {
      return this.$store.getters["respondent/loading"];
    },
    companyColor() {
      const defaultCompanyColor = "#000000";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
  },
  methods: {
    getFormattedDate(joined: any) {
      return helpers.dateFormat(joined);
    },
    assessmentsCandidate(row: any) {
      if (row.status != "COMPLETED") return;
      const u_id = row.id;
      const as_id = u_id.split("-")[0];
      this.$router.push({
        name: "AssessmentsCandidate",
        query: {
          as_id: as_id,
          u_id: btoa(encodeURIComponent(u_id)),
        },
      });
    },
    barWidht(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      return `width: ${pergressPercentage.toFixed(1)}%`;
    },
    fontColor(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      if (+pergressPercentage.toFixed(1) > 50) {
        return true;
      }
      return false;
    },
    copyLink() {
      ElMessage({
        message: (this as any).$t("popup.message.message_8"),
        type: "success",
      });
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(this.publicLink);
    },
    // invite() {
    //   /* eslint-disable */
    //   const email = this.form.email.match(
    //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //   );
    //   /* eslint-enable */
    //   let validate = true;
    //   this.formValidate.email = false;
    //   if (!email) {
    //     this.formValidate.email = true;
    //     validate = false;
    //   }
    //   if (validate) {
    //     console.log("TODO: invite function!");
    //   }
    // },
    status(status: string) {
      return helpers.workflowStatus(status);
    },
    onClickRating(item: any) {
      // don't neccessary to await
      this.$store.commit("assessment/ignoreLoading", true);
      this.$store.dispatch("assessment/saveRating", item, { root: true });
      this.$store.commit("assessment/ignoreLoading", false);
    },
    getList() {
      this.$store.dispatch("respondent/getList", { page: 1 }, { root: true });
    },
    canReSendInvite(row: any) {
      return row.status != "COMPLETED";
    },
    canClearResults(row: any) {
      // @fixed
      // if (row) return true;
      return row.status != "INVITED";
    },
    reSendInviteCls(row: any) {
      return this.canReSendInvite(row) ? "enabled" : "disabled";
    },
    clearResultsCls(row: any) {
      return this.canClearResults(row) ? "enabled" : "disabled";
    },
    async handleCommand(command: string, row: any, event?: any) {
      const assessmentId = row._id;
      switch (command) {
        case "edit":
          break;
        case "delete":
          ElMessageBox.confirm(
            (this as any).$t("popup.question.question_box_3.question"),
            (this as any).$t("popup.question.question_box_3.title"),
            {
              confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
              cancelButtonText: (this as any).$t("popup.optional.Cancel"),
              type: "warning",
            }
          )
            .then(async () => {
              const $row = { form_id: row.formId, email: row.email };
              await this.$store.dispatch("assessment/deleteCandidate", $row, {
                root: true,
              });
              this.getList();
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        case "clone":
          break;
        case "resend":
          if (!this.canReSendInvite(row)) {
            event && event.preventDefault();
            break;
          }
          await this.$store.dispatch(
            "assessment/getFormTemplate",
            { _id: assessmentId },
            { root: true }
          );
          var formTemplate =
            this.$store.getters["assessment/formTemplate"] || {};
          var formDetailsDto = {
            formId: row.formId,
            publicUrl: row.action_url,
            email: row.email,
            firstname: row.firstname,
            lastname: row.lastname,
            section: Object.values(formTemplate.sections),
          };
          await this.$store.dispatch(
            "assessmentTemplate/invite",
            formDetailsDto,
            {
              root: true,
            }
          );
          ElMessage({
            message: (this as any).$t("popup.message.message_2") + row.email,
            type: "success",
          });
          this.getList();
          break;
        case "reset":
          if (!this.canClearResults(row)) {
            event && event.preventDefault();
            break;
          }
          ElMessage({
            message:
              (this as any).$t("popup.message.message_3_1") +
              row.email +
              (this as any).$t("popup.message.message_3_2"),
            type: "success",
          });
          await this.$store.dispatch(
            "assessment/resetCandidate",
            {
              assessmentId: assessmentId,
            },
            {
              root: true,
            }
          );
          await this.$store.dispatch(
            "assessment/getFormTemplate",
            { _id: assessmentId },
            { root: true }
          );
          var formTemplates =
            this.$store.getters["assessment/formTemplate"] || {};
          var formDetailsDtos = {
            formId: row.formId,
            publicUrl: row.action_url,
            email: row.email,
            firstname: row.firstname,
            lastname: row.lastname,
            section: Object.values(formTemplates.sections),
            useSameLink: true,
          };
          await this.$store.dispatch(
            "assessmentTemplate/invite",
            formDetailsDtos,
            {
              root: true,
            }
          );
          this.getList();
          break;
      }
    },
  },
});
